/* index.css */
@font-face {
    font-family: 'Alegreya SC';
    src: url('../fonts/AlegreyaSC-Bold.ttf');
    font-weight: medium;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('../fonts/Noto_Sans/NotoSans-VariableFont_wdth\,wght.ttf');
    font-display: swap;
}

* {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* global element mod. */
a {
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;
 }

html, body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    background: rgba(201, 170, 180, .3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;  /* Improve readability, often used for larger text */
}


/* TOP LANDING SECTION */
.top-landing-page {
    background-image: url('../images/top_background.jpeg');
    background-size: cover;
    -webkit-background-size: cover;
    background-attachment: fixed;
    background-position: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100vh;
}

.sub-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    box-sizing: border-box;
    padding-left: 8px;
    padding-right: 8px;
}

.top-quote {
    max-width: 500px;
    box-sizing: border-box;
    font-family: 'Alegreya SC', 'Noto Sans';
    text-align: center;
    color: rgba(3, 6, 51, 1);
    font-size: 2.4rem;
}

.top-quote > i {
    color: rgba(219, 153, 36, 1);
}

.top-btn-cont {
    display: flex;
    gap: 5px;
}

.request-button {
    inset: all;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    font-family: 'Noto Sans', tahoma;
    font-size: .9rem;
    font-weight: 600;
    border-radius: 5px;
    padding: 6px 10px;
    color: rgba(219, 153, 36, 1);
    background: rgba(26, 48, 120, 1);
    letter-spacing: .1rem;
}

.request-button:hover {
    background: rgba(16, 28, 86, 1);
    /* background: rgba(3, 6, 51, 1); */
}

.top-mid-quote {
    text-align: center;
    color: rgba(3, 6, 51, 1);
    font-size: 24px;
    font-weight: 500;
}


/* HEADER */
.top-main {
    position: absolute;
    width: 100vw;
    height: auto;
    padding-top: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
}

.header-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    gap: 5px;

    list-style-type: none;
    margin: 0;
    padding: 0;
}

.head-link-item {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
   
    padding: 8px 8px;
    margin: 0;
    height: min-content;
    cursor: pointer;
};

.head-logo {
    text-decoration: none;
    color: inherit;
}

.head-link-item > .unselected,
.head-link-item.unselected {
    color: rgba(1, 36, 86, 1);
    font-size: 22px;
    font-weight: 800;
}

.head-link-item > .unselected:hover,
.head-link-item > .unselected:active {
    color: rgba(219, 153, 36, 1);
}

.selected-link {
    font-size: 22px;
    font-weight: 800;
    color: rgba(219, 153, 36, 1);
}

.head-logo {
    position: relative;
    width: 180px;
    height: 120px;
}

.head-logo a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.head-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* DROPDOWN MENU */
#dropdown-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    box-sizing: border-box;
    width: 200px;
    height: auto;
    background: rgba(1, 36, 86, .9);
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 3;
}

#dropdown-menu > a {
    box-sizing: border-box;
    padding: 7px;
    color: white;
    z-index: 10;
}

#dropdown-menu > a:hover {
    box-sizing: border-box;
    color: rgba(219, 153, 36, 1);
}

#dropdown-menu span {
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
}

/* MOBILE MENU */
.mobile-header {
    display: none;
    flex-direction: row;
}

.mobile-btn-wrap {
    position: fixed;
    top: 15px;
    left: 15px;

    box-sizing: border-box;
    padding: 0;
    margin: 0;
    z-index: 20;
}

#mobile-nav-btn {
    display: inline-flex;
    align-items: center;  
    justify-content: center;
    padding: 0; 
    width: 35px; 
    height: 35px; 
    border: 1px solid black;
    border-radius: 5px;
    background: rgba(14, 165, 233, .9);
    fill: rgba(234, 179, 8, 1);
    cursor: pointer;
}

/* nav overlay dialog menu */
#mobile-dialog {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(1, 36, 86, 0.4);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    z-index: 1000;
    border: none;
    outline: none;
}

#mobile-dialog > nav {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
}

.nav-cls-btn-wrp {
    position: absolute;
    box-sizing: border-box;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: end;
    width: 100%;
}

.dialog-lnks-wrap {
    min-height: 300px;
}

.dialog-lnks-wrap > ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.nav-close-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center; 
    padding: 0; 
    width: 35px;
    height: 35px;
    border: 1px solid white;
    border-radius: 5px;
    fill: white;
    background: rgba(1, 1, 1, .4);
    cursor: pointer;
}

.dialog-lnks-wrap .head-link-item > .unselected,
.dialog-lnks-wrap .head-link-item.unselected {
    color: white;
    font-size: 28px;
    cursor: pointer;
}

.dialog-lnks-wrap .head-link-item > .unselected:hover,
.dialog-lnks-wrap .head-link-item > .unselected:active,
#nav-srvc-menu .unselected:active {
    color: rgba(219, 153, 36, 1);
    font-size: 28px;
}

.dialog-lnks-wrap .head-link-item > .selected-link {
    color: rgba(219, 153, 36, 1);
    font-size: 28px;
    background: inherit;
}

#nav-srvc-menu {
    display: none;
    width: 70%;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding-top: 7px;
    padding-bottom: 7px;
}

#nav-srvc-menu a {
    color: rgba(219, 153, 36, 1);
    background: rgba(14, 165, 233, .5);
    border-radius: 5px;
    padding: 4px 10px;
    font-weight: 700;
    font-size: 1.1rem;
}

/* Serve main Quote */
.sec-quote {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 115px;
}

.serv-main-quote {
    font-size: 30px;
    padding: 10px;
    text-align: center;
    color: rgba(1, 36, 86, 0.94);
    letter-spacing: .1rem;
}

/* SERVICES SECTION */
.services-main {
    display: grid;
    grid-template-rows: repeat(4, 300px);
    padding-top: 40px;
    padding-bottom: 40px;
    box-sizing: border-box;
}

.service-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    box-sizing: border-box;
}

.subservice-wrap-desc {
    display: flex;
    flex-direction: column;
    max-width: 355px; 
}

.subservice-wrap-desc > h2 {
    margin: 0;
    color: rgba(3, 6, 51, .9);
    font-family: 'Alegreya SC';
    letter-spacing: .1rem;
    font-size: 1.4rem;
}

.subservice-wrap-desc > p {
    font-family: 'Noto Sans';
    font-weight: 500;
    line-height: 1.2rem;
    font-size: 1rem;
    color: rgba(16, 28, 86, .8);
}

.serv-lrn-butn {
    all: unset;
    text-align: center;
    font-size: 1rem;
    font-weight: 550;
    border-radius: 3px;
    padding: 7px 30px;
    color: rgba(232, 123, 11, 1);
    background: rgba(33, 80, 173, 1);
    min-width: max-content;
}

.serv-lrn-butn:hover {
    background: rgba(16, 28, 86, 1);
    /* border: .5px solid white; */
}

.srv-img {
    height: 260px;
}

.srv-img > img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

#win-wash-wrap {
    width: 343px;
}

#roofgut-img-wrap {
    width: 334px;
}

#pwrwash-img-wrap {
    width: 324px;
}

#solrp-img-wrap {
    width: 324px;
    height: 265px;
}

/* quote-request-section */
.quote-request-section {
    min-height: 500px;
    position: relative;
}

.quote-request-section > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
}


.req-sec-overlay {
    background: rgba(1, 36, 86, .3);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    box-sizing: border-box;
    width: 100%;
    min-height: 500px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    z-index: 3;
}

.req-content-wrap {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 13px;
    padding: 15px;
    max-width: 500px;
}

.req-content-wrap  > span {
    font-family: 'Alegreya SC';
    font-size: 3.8rem;
    font-weight: 500;
    letter-spacing: .1rem;
    color: rgba(240, 233, 221, 1);
}

.req-button {
    all: unset;
    padding: 8px 20px;
    background: rgba(33, 80, 173, 1);
    color: rgba(232, 123, 11, 1);
    font-size: 1rem;
    font-weight: 550;
}

.req-button:hover {
    background: rgba(1, 36, 86, 1);
}

/* QUOTE SCROLL SECTION */
#quote-slider-wrap {
    min-height: 445px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
}

.divider-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 35px;
    min-width: 100%;
}

.divider-spn {
    width: 70%;
    height: 2.5px;
    background: rgba(1, 36, 86, 0.94);
}

.slider-title {
    text-align: center;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1.9rem;
    letter-spacing: .1rem;
    color: rgba(1, 26, 65, 1);
}

.slider {
    box-sizing: border-box;
    height: 245px;
    width: 470px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: rgba(33, 80, 173, .1);
    position: relative;
    overflow: hidden;
}

#slide-frame {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
}

.quote-slide {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 35px;
    padding-right: 35px;
    width: 100%;
}

.quote-slide > .quote-text {
    margin: 0;
    letter-spacing: .1rem;
    box-sizing: border-box;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 400px;
    font-style: italic;
}

.quote-slide > span {
    font-size: .9rem;
}


.scroll-slider {
    position: absolute;
    height: 50px;
    width: 40px;
    border: none;
    padding: 2px;
    background: none;
    z-index: 5;
}

.scroll-l {
    left: 0;
}

.scroll-r {
    right: 0;
}

#Capa_r {
    width: 100%;
    height: 100%;
    transform: rotate(90deg);
}

#Capa_l {
    width: 100%;
    height: 100%;
    transform: rotate(270deg);
}

/* LOWER INTRO SECTION  */
.intro-wrap {
    box-sizing: border-box;
    min-height: 660px;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.description-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    max-width: 350px;
}

.description-left > h4 {
    color: rgba(1, 36, 86, 0.94);
}

.description-left > p,
.description-left > h4 {
    font-family: 'Noto Sans';
    line-height: 1.1rem;
    padding: 5px;
    margin: 0;
}

.description-left > p {
    padding-bottom: 15px;
}

.description-left a {
    font-family: inherit;
}

.low-intro-title {
    width: 100%;
    text-align: center;
    font-family: 'Noto Sans', Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.7rem;
    font-weight: 900;
    color: rgba(3, 6, 51, .9);
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    padding-left: 10px;
}

.intro-img-right {
    box-sizing: border-box;
    display: block;
    aspect-ratio: 1 / 1.22;
    width: 90%;
    max-width: 350px;
    height: auto;
    object-fit: contain;
}

.intro-img-right > img {
    width: 100%;
    height: 100%;
}

.req-link {
    cursor: pointer;
}

.contact-lower-button {
    inset: all;
    border: none;
    box-sizing: border-box;
    font-family: tahoma;
    cursor: pointer;
    font-size: 13px;
    font-weight: 550;
    letter-spacing: .1rem;
    border-radius: 3px;
    padding: 10px 30px;
    color: rgba(219, 153, 36, 1);
    background: rgba(33, 80, 173, 1);
}

.contact-lower-button:hover {
    background: rgb(16, 44, 100);
}

/* preFooter */
#preFooter {
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: inherit;
    -webkit-font-smoothing: antialiased;
}

.svg-icon--list {
    display: flex;
    gap: 8px;
    width: max-content;
    height: max-content;
    padding: 25px;
}

.svg-icon--list a {
    color: rgb(16, 44, 100);
    text-decoration: none;
    background: transparent;
    border-radius: 50%;
}


.svg-icon--wrapper {
    box-sizing: border-box;
    width: 36px;
    height: 36px;
    box-shadow: 0 0 0 2px inset;
    border: none;
}

.svg-icon--social {
    background-color: inherit;
    color: #c9c9c9;
    border-radius: 50%;
}

.facebook > .use--icon {
    fill: white;
}

.yelp > .use--icon {
    fill: red;
}

.use--mask {
    fill: rgba(14, 165, 233, 1);
    border-radius: 50%;
}

.yelp > .use--mask {
    fill: transparent;
}

/* FOOTER SECTION */
.index-footer {
    min-height: 430px;
    display: flex;
    justify-content: center;
    background: rgba(14, 165, 233, 1);
    position: relative;
}

#siteInfo {
    display: none;
    position: absolute;
    top: 5;
}

#siteInfo > span {
    color: rgba(255, 255, 255, .8);
}
 
.logo-foot-wrap {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
}

.foot-contact-left {
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 430px;
}

.foot-contact-left > h2 {
    margin: 0;
    font-size: 1.7rem;
}

.foot-contact-left > h3 {
    color: rgba(234, 179, 8, 1);
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Noto Sans';
}

.foot-contact-left > span {
    font-size: .6rem;
}
 
.footer-logo {
    box-sizing: border-box;
    background-color: rgba(234, 179, 8, .9);
    display: block;
    aspect-ratio: 1.53 / 1;
    width: 90%;
    max-width: 400px;
    height: auto;
    border: 1px solid rgba(234, 179, 8, .9);
    border-radius: 5px;
}

.footer-logo > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.logo-foot-wrap > div > h2 {
    color: rgba(240, 233, 221, 1);
}

.foot-links {
    display: flex;
    gap: 12px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-top: 25px;
    padding-bottom: 5px;
}

#phone-number {
    margin: 0;
    padding: 0;
    width: max-content;
    font-size: 2.5rem;
    color: rgba(240, 233, 221, 1);
    border-bottom: .1px solid rgba(240, 233, 221, .4);
    padding-bottom: 3px;
    font-family: 'Noto Sans';
}

#phone-number > a {
    color: inherit;
}

#phone-number > a:hover {
    color: rgba(219, 153, 36, .8);
}

.foot-link {
    border-bottom: .1px solid rgba(240, 233, 221, .4);
}

.foot-link > a {
    color: rgba(240, 233, 221, 1);
    /* color: rgba(219, 153, 36, 1); */
    font-size: .9rem;
    font-weight: 500;
    
}


/* MEDIA QUERIES */
@media screen and (width < 741px) {
    .top-landing-page {
        background-attachment: scroll;
    }

    .sub-content {
        height: 50%;
    }

    /* main section */
    .services-main {
        grid-template-rows: repeat(4, 600px);
    }

    .service-wrap {
        flex-direction: column;
    }

    .subservice-wrap-desc {
        justify-content: center;
        align-items: center;
        padding-left: 8px;
        padding-right: 8px;
        padding: 3px;
    }

    .subwinwash,
    .subpwrwash  {
        order: 2;
    }
    
    .win-wash,
    .pwr-wash {
        order: 1;
    }

    /* quote slider */
    .slider {
        width: 400px;
        height: max-content;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .quote-slide > .quote-text {
        width: 330px;
    }

    /* req img */
    .slider-title {
        font-size: 1.8rem;
    }

    /* request section */
    .req-content-wrap {
        max-width: 100%;
        align-items: center;
    }

    .req-content-wrap > span {
        font-size: 3.5rem;
    }

    /* LOWER INTRO WRAP */
    .intro-wrap {
        flex-direction: column;
        min-width: 330px;
        padding-bottom: 35px;
    }

    /* footer */
    .logo-foot-wrap {
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 30px;
    }

    .foot-contact-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 30px;
    }

    .foot-contact-left > h2 {
        text-align: center;
    }

    .foot-links {
        padding-top: 35px;
        padding-bottom: 5px;
    }

    #phone-number > a:active {
        color: rgba(219, 153, 36, 1);
    }

    .footer-logo {
        display: block;
        aspect-ratio: 1.53 / 1;
        width: 90%;
        max-width: 400px;
        height: auto;
        border: 1px solid rgba(234, 179, 8, .9);
        border-radius: 5px;
        object-fit: contain;
    }

/* non-mobile-header */
    .top-main {
        display: none;
    }

 /* MOBILE NAV MENU */
    .mobile-header {
        position: absolute;
        top: 0;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }

    .head-logo {
        width: 200px;
        height: 130px;
    }
}

@media screen and (width < 401px) { 
    .top-btn-cont {
        flex-direction: column;
        align-items: center;
    }

    .serv-main-quote {
        padding-top: 30px;
    }

    .subservice-wrap-desc > h2 {
        font-size: 1.3rem;
    }

    /* quote-slider */
    .slider-title {
        font-size: 1.7rem;
    }

    .slider {
        width: 300px;
        height: max-content;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .quote-slide > .quote-text {
        width: 230px;
    }

    /* request section */
    .req-sec-overlay {
        align-items: center;
        padding-left: 0px;
    }

    .req-content-wrap > span {
        font-size: 3rem;
    }

    /* footer  */
    .foot-link > a {
        font-size: .8rem;
    }

    #phone-number {
        font-size: 2.1rem;
    }

    .foot-links {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 9px;
    }
}

